<template>
  <v-container fluid>
    <CreateRound @created="getRounds" />
    <v-row justify="center">
      <v-col cols="11" class="d-flex justify-start">
        <v-btn color="primary" fab @click="goToChampionships()">
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="11">
        <v-expand-transition>
          <loader v-if="loading" />
          <v-row v-else>
            <UpdateChampionship
              :id="id"
              :championshipData="championship"
              @updated="getChampionship"
            />
            <DeleteChampionship
              :championshipData="championship"
              @deleted="goToChampionships"
            />
            <UpdateRound
              :data="selectedRound"
              @updated="getRounds"
            />
            <v-col cols="12">
              <v-card>
                <v-row justify="center">
                  <v-col
                    lg="3"
                    md="4"
                    sm="5"
                    cols="10"
                  >
                    <v-avatar
                      size="200"
                      color="#F7F7F7"
                      class="ma-3 championship-image"
                    >
                      <v-img
                        v-if="championship.image"
                        :src="championship.image"
                        contain
                        max-height="70%"
                        @error="championshipImageErrorHandler()"
                      />

                      <span
                        v-else
                        class="display-2 white--text text-center"
                      >
                        <v-icon>
                          mdi-trophy
                        </v-icon>
                      </span>
                    </v-avatar>
                  </v-col>
                  <v-col
                    lg="9"
                    md="8"
                    sm="7"
                    cols="10"
                    class="mt-7"
                  >
                    <v-row>
                      <v-col
                        lg="8"
                        md="8"
                        sm="12"
                        cols="12"
                        class="d-flex flex-column align-start justify-start text-start"
                      >
                        <h2>{{championship.name}}</h2>
                        <h3 style="font-weight: normal">
                          De {{formatDate(championship.startsAt)}} à {{formatDate(championship.endsAt)}}<br>
                          {{padWithZeros(championship.participants)}} Participantes <br>
                          {{padWithZeros(championship.teams.length)}} Times
                        </h3>
                      </v-col>
                      <v-col
                        lg="4"
                        md="4"
                        sm="12"
                        cols="12"
                        class="d-flex flex-column"
                        :class="['xs', 'sm'].includes(screenSize()) ? 'justify-start' : 'justify-center'"
                      >
                        <v-btn color="primary" @click="updateChampionship()" width="150px">
                          Editar
                        </v-btn>
                        <br>
                        <v-btn color="primary" @click="deleteChampionship()" width="150px">
                          Excluir
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card>
                <v-card-title class="d-flex justify-space-between">
                  <h3>Rodadas</h3>
                  <v-btn color="primary" @click="createRound()">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-row v-if="loadingRounds">
                    <v-col
                      v-for="i in 3"
                      :key="`roundloader-${i}`"
                      lg="4"
                      md="4"
                      sm="4"
                      cols="12"
                    >
                      <v-skeleton-loader
                        class="mb-6"
                        elevation="1"
                        type="article"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-else justify-start class="mx-1 my-1">
                    <v-col
                      v-for="round in roundsItens"
                      :key="round._id"
                      lg="3"
                      md="6"
                      sm="12"
                      cols="12"
                    >
                      <RoundCard
                        :round="round"
                        @updateSelected="updateRound"
                      />
                    </v-col>

                    <v-col v-if="rounds.length > 4" cols="12" class="d-flex justify-center">
                      <v-btn text @click="changeRoundsListSize()">
                        <v-icon>{{ showAllRounds ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        {{ showAllRounds ? 'Ver menos' : 'Ver tudo' }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card>
                <v-card-title>
                  <h3>Times</h3>
                </v-card-title>
                <v-card-text>
                  <v-row justify-start class="mx-1 my-1">
                    <v-col
                      v-for="(team, index) in teams"
                      :key="index"
                      lg="3"
                      md="6"
                      sm="6"
                      cols="12"
                    >
                      <div class="team-card d-flex justify-start mr-3">
                        <v-avatar
                          tile
                          left
                          size="50"
                          color="#F7F7F7"
                          class="ma-3 team-image"
                        >
                          <v-img
                            v-if="team.image"
                            :src="team.image"
                            contain
                            max-height="70%"
                            @error="teamImageErrorHandler(index)"
                          />

                          <span
                            v-else
                            class="display-2 white--text text-center"
                          >
                            <v-icon>
                              mdi-soccer
                            </v-icon>
                          </span>
                        </v-avatar>
                        <span v-text="team.name" class="text-start mt-3" style="font-weight: bold" />
                      </div>
                    </v-col>
                    <v-col v-if="championship.teams.length > 4" cols="12" class="d-flex justify-center">
                      <v-btn text @click="changeTeamsListSize()">
                        <v-icon>{{ showAllTeams ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        {{ showAllTeams ? 'Ver menos' : 'Ver tudo' }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-expand-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const imageOffIcon = require('@/assets/icons/image-off.png')

export default {
  components: {
    Loader: () => import('@/components/LoadingDetails'),
    UpdateChampionship: () => import('./UpdateChampionship.vue'),
    DeleteChampionship: () => import('./DeleteChampionship.vue'),
    RoundCard: () => import('../../rounds/components/RoundCard.vue'),
    CreateRound: () => import('../../rounds/components/CreateRound.vue'),
    UpdateRound: () => import('../../rounds/components/UpdateRound.vue')
  },
  computed: {
    updateChampionshipModal: {
      get () {
        return this.$store.state.updateChampionshipModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'updateChampionshipModal',
          value: val
        })
      }
    },
    deleteChampionshipModal: {
      get () {
        return this.$store.state.deleteChampionshipModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'deleteChampionshipModal',
          value: val
        })
      }
    },
    teams () {
      if (this.showAllTeams) {
        return this.championship.teams
      }
      return this.championship.teams.slice(0, 4)
    },
    roundsItens () {
      if (this.showAllRounds) {
        return this.rounds
      }
      return this.rounds.slice(0, 4)
    },
    createRoundModal: {
      get () {
        return this.$store.state.createRoundModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'createRoundModal',
          value: val
        })
      }
    },
    updateRoundModal: {
      get () {
        return this.$store.state.updateRoundModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'updateRoundModal',
          value: val
        })
      }
    }
  },
  data () {
    return {
      loading: true,
      loadingRounds: true,
      id: null,
      championship: null,
      selectedRound: {},
      rounds: [],
      showAllTeams: false,
      showAllRounds: false
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.getChampionship()
    this.getRounds()
  },
  methods: {
    getChampionship () {
      if (!this.id) return
      this.loading = true

      this.$http.get(`championships/${this.id}`)
        .then(res => {
          const { data } = res.data
          this.championship = data
        })
        .catch(err => {
          this.$toast.error(err.response.data.message)
        })
        .finally(() => (this.loading = false))
    },
    getRounds () {
      if (!this.id) return
      this.loadingRounds = true

      this.$http.get(`championships/${this.id}/rounds`)
        .then(res => {
          const { data } = res.data
          this.rounds = data.sort((roundA, roundB) => roundA.number - roundB.number)
        })
        .catch(err => {
          this.$toast.error(err.response.data.message)
        })
        .finally(() => (this.loadingRounds = false))
    },
    championshipImageErrorHandler () {
      this.championship.image = imageOffIcon
    },
    teamImageErrorHandler (index) {
      this.championship.teams[index].image = imageOffIcon
    },
    updateChampionship () {
      this.updateChampionshipModal = true
    },
    changeTeamsListSize () {
      this.showAllTeams = !this.showAllTeams
    },
    changeRoundsListSize () {
      this.showAllRounds = !this.showAllRounds
    },
    createRound () {
      this.$store.commit('SET_SELECTED_CHAMPIONSHIP', this.id)
      this.createRoundModal = true
    },
    goToChampionships () {
      this.$router.push('/campeonatos')
    },
    updateRound (data) {
      this.selectedRound = data
      this.updateRoundModal = true
    },
    deleteChampionship () {
      this.deleteChampionshipModal = true
    }
  }
}
</script>

<style scoped>
  .team-card {
    border: 1px solid #BDBDBD !important;
    border-radius: 3px !important;
  }

  .team-image {
    border: 1px solid #BDBDBD !important;
    border-radius: 3px !important;
  }
</style>
